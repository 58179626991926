import axios from "axios";
import { eventBus } from "@/main";

const moduleTreatmentPlan = {
  namespaced: true,
  state: {
    planList: [],
    activePlan: {},
    treatmentPlanItems: [],
    treatmentPlan: [],
  },
  mutations: {
    setPlanList(state, data) {
      state.planList = data || [];
    },
    setActivePlan(state, data) {
      state.activePlan = data || {};
      state.treatmentPlanItems = data.treatmentPlanItems || [];
    },
    updateTreatmentPlanItem(state, data) {
      state.treatmentPlanItems = [
        ...state.treatmentPlanItems.map(item =>
          item.id !== data.id ? item : { ...item, ...data, isUpdating: false }
        ),
      ];
      data;
    },
    setTreatmentPlan(state, data) {
      state.treatmentPlan = data;
    },
  },
  actions: {
    async createTreatmentPlan({ commit }, payload) {
      return await axios
        .post(
          `/api/treatmentPlan/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.data
        )
        .then(res => {
          commit("setTreatmentPlan", res.data);
          if (res.data.status === "Active") {
            commit("setActivePlan", res.data);
          }
          return res.data;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    async updateTreatmentPlan({ commit }, payload) {
      return await axios
        .put(
          `/api/treatmentPlan/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.data
        )
        .then(res => {
          commit("setTreatmentPlan", res.data);
          return res.data;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchTreatmentPlans({ commit }, payload) {
      commit("setPlanList", []);
      commit("setActivePlan", []);

      return axios
        .get(
          `/api/treatmentPlan/${payload.clinicUrl}/${payload.branchUrl}/${payload.patientId}`
        )
        .then(res => {
          console.log(res);
          const planList = res.data;
          commit("setPlanList", res.planList);
          const activePlan =
            planList.filter(i => i.status === "Active")[0] || {};
          commit("setActivePlan", activePlan);
          return res.data;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    async updateTreatmentPlanItem({ commit }, payload) {
      const id = payload.data.id;
      delete payload.data.id;
      return await axios
        .put(
          `/api/treatmentPlanItem/${payload.clinicUrl}/${payload.branchUrl}/${id}`,
          payload.data
        )
        .then(res => {
          commit("updateTreatmentPlanItem", res.data);
          return res.data;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
  },
  getters: {
    getTreatmentPlans: state => {
      return state.treatmentPlans;
    },
    getActiveTreatmentPlan: state => {
      return state.activePlan;
    },
    getTreamentPlanItems: state => {
      return state.treatmentPlanItems?.sort((a, b) => a - b) || [];
    },
  },
};
export default moduleTreatmentPlan;
